@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600;700&display=swap');



:root {
  --brading-color: #5273e9;
}

*{
  box-sizing: border-box;
  text-decoration: none;
}

#root{
  width:100%;
  height:100%;
}

body{
  height: 100vh;
  width:100vw;
}

.brand-name{
  color:white;
  font-size: 19px;
  font-family:"Audiowide"
}

.brand-name span{
  color:#b6d9f4;

}

.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.app-container, .content-container{
  width:100%;
  
}

.app-container{
  display:flex;
  flex-direction: row;
  height:100%;
  background-color: rgba(245, 245, 245, 0.976);
}

.content-container{
  margin:4% 2%;
  height:88%;
  
  border-bottom:2px solid red;
  border-right:2px solid red;
}

.dashboard-content{
 
  height: 100%;
}

.container-row{
  display: flex;
  flex-direction: row;
}