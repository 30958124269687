#root{
    --sidebar-option-icon:rgba(0,0,0,0.5);
    --sidebar-option-text:rgba(0,0,0,0.9);
    --sidebar-option-hover:rgba(0,20,255,0.7);
    --sidebar-option-divider:rgba(0,0,0,0.2);
    --sidebar-option-border:rgba(0,0,0,0.2);
    --sidebar-logo-title:rgba(0,20,255,0.7);
}

.sidebar{
    height: 100%;
    background-color: white;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    padding:10px 10px;
    flex-direction: column;
    width: 250px;
    border-right:1px solid var(--sidebar-option-border);

    /* border: 3px solid red; */
  }
  
  .sidebar-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:100%;
  }

  .sidebar-logo--title{
    font-size: 26px;
    color:var(--sidebar-logo-title);
  }

  .sidebar-menu{
    width:100%;
    margin-top:15px;
    text-align: center;
  }

  .sidebar-option{
    width:100%;
    height:50px;
    margin:10px 0px;
    padding:10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center ;
    border-radius:12px;
    cursor:pointer;
    /* border:3px solid blue; */
  }

  .sidebar-option:hover{
    background-color: var(--sidebar-option-hover);
    transition: 0.2s ease;
  }

  .sidebar-option--title{
    margin-left: 10px;
    font-size:17px;
    font-weight:500;
    color:var(--sidebar-option-text);
    transition: 0.2s ease-in;
  }
  
  .sidebar-option:hover .sidebar-option--title{
    color:white;
  }
  
  .sidebar-option--icon{
    color:var(--sidebar-option-icon);
    transition: 0.2s ease-in;
    cursor:pointer;

  }

  .sidebar-option:hover .sidebar-option--icon{
    color:white;
  }
 
  .sidebar-options--divider{
    border-top: 2px solid #bbb;
    border-radius: 2px;
    width:90%;
    border-color:var(--sidebar-option-divider);
    justify-self: center;
  }