.react-calendar {
    width:100%;
    background: white;
    padding:20px 12px;
    /* border: 1px solid rgba(0,0,0,0.2); */
    font-family: 'Montserrat', sans-serif;
    line-height: 1.125em;
    /* box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.056); */
    border-radius: 18px;

  }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
.react-calendar__navigation span{
    font-size:16px;
    font-family: 'Montserrat', sans-serif;
    font-weight:600;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    color:rgb(47, 57, 162);
    font-weight: bold;
    font-size: 0.75em;
    font-family: 'Montserrat', sans-serif;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #000ad1be;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    border-radius: 5px;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar__tile--now {
    background: #768dff6d;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #768dff56;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
.calendar-widget{
    min-width: 425px;
    width:100%;
    max-width: 650px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    padding:10px 8px;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.056);
}

.upcoming-meetings{
    margin-top: 15px;
    height:50px;
    width:100%;
    border:3px solid red;
    border-radius: 18px;
}

.calendar-widget--meetings{
    padding:0px 12px;
    display: flex;
    flex-direction: column;
}

.meetings-text{
    display: flex;
    flex-direction: row;
    align-items: center;   
}

.meetings-number{
    margin-left:10px;
    height: 25px;
    width: 25px;
    background-color: blue;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    color:white;
    font-weight: 700;
}

.meeting-tile{
    width:100%;
    height:150px;
    border: 1px solid rgba(0, 0, 0, 0.105);
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:10px 15px;
}

.meeting-info{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.meeting-info--user{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.meeting-patient{
    margin-left:25px;
}

.meeting-hour{
    background-color: rgba(60, 14, 160, 0.804);
    padding:8px;
    border-radius: 6px;
    color:white;
}

.navigate-meetings{
    display: flex;
    width:50px;
    justify-content: space-between;
}

.arrow-icons{
    color:gray;
    cursor:pointer;
}
.meeting-reschedule{
    height:48px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(235, 235, 243, 0.649);
    border-radius: 10px;
    color:rgba(60, 14, 160, 0.804);
    font-weight: 500;
    cursor:pointer;
}